import { HttpErrorResponse } from '@angular/common/http';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import {
  SvcustomersResponse,
  SvWalletAndCustomerParams,
  SvwalletsResponse,
} from 'src/app/appdata/shared.data';

export const getFiletrsData = createAction(
  '[Service Accounts] Get Filetrs Data',
  props<{
    customerParams: SvWalletAndCustomerParams;
    walletParams: SvWalletAndCustomerParams;
  }>()
);

export const GetSvCustomers = createActionGroup({
  source: 'SVCustomers',
  events: {
    'Get SVCustomers': props<{
      params: SvWalletAndCustomerParams;
      filter?: boolean;
    }>(),
    'Get SVCustomers Success': props<{ data: SvcustomersResponse['data'] }>(),
    'Get SVCustomers Failure': props<{ error: HttpErrorResponse }>(),
  },
});

export const GetSvWallets = createActionGroup({
  source: 'SVWallets',
  events: {
    'Get SVWallets': props<{
      params: SvWalletAndCustomerParams;
      filter?: boolean;
    }>(),
    'Get SVWallets Success': props<{ data: SvwalletsResponse['data'] }>(),
    'Get SVWallets Failure': props<{ error: HttpErrorResponse }>(),
  },
});
