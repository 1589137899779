import { createFeature, createReducer, on } from '@ngrx/store';
import { GetSvWallets } from './serviceaccount.actions';
import { IAppState, SvwalletsResponse } from 'src/app/appdata/shared.data';

export interface SvwalletsState
  extends IAppState<
    'Svwallets',
    'data' | 'isLoading' | 'currentPage' | 'pageSize' | 'error',
    SvwalletsResponse['data'] | null
  > {}

export const initialSvWalletsState: SvwalletsState = {
  Svwalletsdata: null,
  SvwalletsisLoading: false,
  SvwalletscurrentPage: 1,
  SvwalletspageSize: 10,
  Svwalletserror: null,
};

export const SvWalletsFeature = createFeature({
  name: 'SvWallets',
  reducer: createReducer(
    initialSvWalletsState,
    on(
      GetSvWallets.getSVWallets,
      (state): SvwalletsState => ({
        ...state,
        SvwalletsisLoading: true,
        Svwalletserror: null,
      })
    ),
    on(
      GetSvWallets.getSVWalletsSuccess,
      (state, { data }): SvwalletsState => ({
        ...state,
        Svwalletsdata: data,
        SvwalletsisLoading: false,
        Svwalletserror: null,
      })
    ),
    on(
      GetSvWallets.getSVWalletsFailure,
      (state, { error }): SvwalletsState => ({
        ...state,
        SvwalletsisLoading: false,
        Svwalletserror: error,
      })
    )
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectSvWalletsState, // feature selector
  selectSvwalletsdata, // selector for `books` property
  selectSvwalletsisLoading, // selector for `loading` property
  selectSvwalletserror, //selector for `error` property
} = SvWalletsFeature;
