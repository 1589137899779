<!-- Main Content Container -->
<main role="main" class="main" id="cc_business_accounts">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate | async"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- Skeleton Template -->
<ng-template #skeletonTemp>
  <div class="card mt-2">
    <ngx-skeleton-loader
      class="skeleton_loader"
      *ngFor="let item of skeletonData"
      [theme]="item"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
<!-- END Skeleton Template -->

<!-- Empty Template -->
<ng-template #noTemplates>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <h3 class="main_title">Business Accounts</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- template Content -->
  <div class="card mt-2">
    <div class="empty_template text-center my-4">
      <img
        class="w-30"
        src="assets/img/no_data.svg"
        alt="New Business Account"
      />
      <h4 class="mb-3">
        There are no business accounts added in this organization
      </h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient"
        (click)="onCreateBusinessAccount()"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Add New Business Account
      </button>
    </div>
  </div>
  <!-- END template Content -->
</ng-template>
<!-- END Empty Template -->

<!-- Business Accounts List Template -->
<ng-template #allBusinessAccountsTemp>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <h3 class="main_title">
        {{ getAccountName() }} Accounts
        <span
          class="badge badge-primary rounded-pill"
          matTooltip="Total Business Accounts Count"
          >{{ accountsResponse.count }}</span
        >
      </h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card mt-2">
    <div class="card-body p-0">
      <!-- Header Options -->
      <div class="row justify-content-between py-2">
        <div class="col-4">
          <!-- SearchBar -->
          <div class="input-group input-group-merge m-0">
            <span class="input-group-prepend input-group-text"
              ><i class="bi-search"></i
            ></span>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              [ngClass]="
                searchData?.length < 3 && searchData?.length > 0
                  ? 'invalid-input'
                  : ''
              "
            />
          </div>
          <!-- END SearchBar -->
        </div>

        <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
          <form [formGroup]="ssoSuperAdminFilters" (ngSubmit)="applyFilter()">
            <ul class="list-inline list-inline-flex mb-0">
              @if (sso &&
              loggedInUserDetails.account.account_type.toUpperCase()==="ROOT") {
              <!-- Filters -->
              <li class="list-inline-item">
                <div
                  ngbDropdown
                  class="d-inline-block filter-dropdown"
                  (openChange)="getFilterData($event)"
                  #myDrop="ngbDropdown"
                >
                  <button
                    type="button"
                    class="btn btn-default"
                    id="CampaignfiltersDropDown"
                    ngbDropdownToggle
                  >
                    <i class="bi bi-funnel me-1"></i>
                    Filters
                  </button>
                  <div
                    class="dropdown-menu"
                    ngbDropdownMenu
                    aria-labelledby="CampaignfiltersDropDown"
                  >
                    <div class="card p-0 shadow-none">
                      <!-- Type Ng-select -->
                      <div class="card-header">
                        <h4 class="title">Filters</h4>
                      </div>
                      <div
                        class="card-body py-2 px-3"
                        (click)="stopPropagation($event)"
                        (keydown)="stopPropagation($event)"
                      >
                        <!-- Wallet Mode Selection -->
                        <div class="form-group">
                          <div class="input-label">Wallet Mode</div>
                          <ng-select
                            [items]="Wallet_Mode"
                            bindLabel="name"
                            bindValue="id"
                            (change)="onChooseFilter($event, 'Wallet_Mode')"
                            [placeholder]="'-- Select --'"
                            [clearable]="false"
                            [searchable]="false"
                            [readonly]="false"
                            formControlName="wallet_mode"
                          >
                          </ng-select>
                        </div>
                        <!-- END Wallet Mode Selection -->

                        <!-- BSP Type Selection -->
                        <div class="form-group">
                          <div class="input-label">BSP</div>
                          <ng-select
                            [items]="bspList"
                            bindLabel="name"
                            bindValue="name"
                            (change)="onChooseFilter($event, 'BSP')"
                            [placeholder]="'-- Select --'"
                            [clearable]="false"
                            [searchable]="false"
                            formControlName="bsp"
                          >
                          </ng-select>
                        </div>
                        <!-- END BSP Type Selection -->

                        <!-- Customers -->
                        <div class="form-group">
                          <div class="input-label">Sv Customer Id</div>
                          <ng-select
                            [items]="Svcustomerdata?.customers_data"
                            bindLabel="sv_customer_id"
                            bindValue="sv_customer_id"
                            (change)="onChooseFilter($event, 'Customer')"
                            [placeholder]="'-- Select --'"
                            [clearable]="false"
                            [searchable]="true"
                            (search)="onSearch($event, 'Customer')"
                            [virtualScroll]="true"
                            [loading]="selectSvcustomerisLoading | async"
                            (scrollToEnd)="onScrollToEnd('Customer')"
                            (open)="onDropDownStatus('Customer')"
                            formControlName="sv_customer_id"
                          >
                          </ng-select>
                        </div>
                        <!-- End Customers -->

                        <!-- Wallets -->
                        <div class="form-group">
                          <div class="input-label">Wallets</div>
                          <ng-select
                            [items]="Svwalletsdata?.wallets_data"
                            bindLabel="wallet_name"
                            bindValue="wallet_name"
                            (change)="onChooseFilter($event, 'Wallets')"
                            [placeholder]="'-- Select --'"
                            [clearable]="false"
                            [searchable]="true"
                            (search)="onSearch($event, 'Wallets')"
                            [virtualScroll]="true"
                            [loading]="selectSvwalletsisLoading | async"
                            (scrollToEnd)="onScrollToEnd('Wallets')"
                            (open)="onDropDownStatus('Wallets')"
                            formControlName="wallet_name"
                          >
                          </ng-select>
                        </div>
                        <!-- End Wallets -->
                      </div>
                    </div>

                    <!-- Apply Filters Button -->
                    <!-- <div class="card-footer">
                      <button
                        type="submit"
                        class="btn btn-block btn-gradient"
                        (click)="
                          applyFilter();
                          $event.stopPropagation();
                          myDrop.close()
                        "
                        *ngIf="
                          ssoParams.wallet_mode ||
                          ssoParams.bsp ||
                          ssoParams.sv_customer_id ||
                          ssoParams.wallet_name
                        "
                      >
                        Apply
                      </button>
                    </div> -->
                    <!-- END Apply Filters Button -->
                  </div>
                </div>
              </li>
              <!-- END Filters -->
              } @else {
              <!-- BSP Selection -->
              <li class="list-inline-item">
                <ng-select
                  class="header_select"
                  [items]="bspList"
                  [multiple]="false"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [clearable]="true"
                  placeholder="Select BSP"
                  [(ngModel)]="selectedBSP"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span>{{ item.bsp_name }}</span>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    <span>{{ item.bsp_name }}</span>
                  </ng-template>
                </ng-select>
              </li>
              <!-- END BSP Selection -->
              }
            </ul>
          </form>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <!-- Filter Selected Values -->
    @if (ssoParams.wallet_mode || ssoParams.bsp ||
    ssoParams.sv_customer_id||ssoParams.wallet_name) {
    <div
      class="selected_filters mt-2 p-2 d-flex align-items-center justify-content-between"
    >
      <!-- Selected Filter Options -->
      <div class="selected_items">
        <mat-chip-listbox aria-label="Selected Type and Status">
          <mat-chip *ngIf="ssoParams.wallet_mode">
            Type: <b>{{ ssoParams.wallet_mode | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('wallet_mode')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="ssoParams.sv_customer_id">
            Type: <b>{{ ssoParams.sv_customer_id | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('sv_customer_id')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="ssoParams.bsp">
            Type: <b>{{ ssoParams.bsp | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('bsp')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-chip *ngIf="ssoParams.wallet_name">
            Type: <b>{{ ssoParams.wallet_name | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('wallet_name')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Selected Filter Options -->

      <!-- Clear Filters Option -->
      <div class="clear_filters">
        <mat-chip-listbox aria-label="Clear selected filters">
          <mat-chip
            (click)="clearFilter('clearfilter')"
            (keydown)="clearFilter('clearfilter')"
          >
            Clear All
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Clear Filters Option -->
    </div>
    }
    <!-- END Filter Selected Values -->

    <div *ngIf="channel_credentails()?.length > 0; else nodata">
      <!-- Business Accounts List -->
      <!-- <div
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-gap-4 mt-3"
      > -->
      <div class="table-wrapper table-responsive">
        <table
          class="table table-sm table-striped"
          aria-describedby="serviceActList"
        >
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col">Name</th>
              <th scope="col">SV Customer ID</th>
              <th scope="col">Customer ID</th>
              <th scope="col">Customer Org</th>
              <th scope="col">Wallets Name</th>
              <th scope="col">Status</th>
              <th scope="col">Created On</th>
              <!-- <th scope="col"></th> -->
            </tr>
          </thead>
          <tbody class="table-body">
            @for (item of channel_credentails(); track $index) {
            <tr class="table-row">
              <td class="table-data fw-bold">
                {{ item.service_account_name | uppercase }}
              </td>
              <td class="table-data">{{ item.sv_customer_id }}</td>
              <td class="table-data">{{ item.customer_id }}</td>
              <td class="table-data">{{ item.organization }}</td>
              <td class="table-data">{{ item.wallet_name }}</td>
              <td class="table-data">
                {{
                  !item.waba_status &&
                  item.waba_status?.toLowerCase() !== "connected"
                    ? "Inactive"
                    : "Active"
                }}
              </td>
              <td class="table-data">
                <div class="d-flex justify-content-between">
                  {{ item.created_at | date }}
                  <!-- Select bact button -->
                  <button
                    class="btn btn-default btn-icon btn-sm"
                    (click)="onViewBusinessAccount(item)"
                    [disabled]="
                      !item.waba_status &&
                      item.waba_status?.toLowerCase() !== 'connected'
                    "
                    *ngIf="
                      permissions.waba_business_account_management.can_update ||
                      permissions.waba_business_account_management.can_view
                    "
                  >
                    <em class="bi-arrow-right"></em>
                  </button>
                  <!-- END Select bact button -->
                </div>
              </td>
              <td class="table-data"></td>
            </tr>
            }
            <!-- @if(selectSvcustomerisLoading | async){
                <tr class="table-row">
                  <td colspan="100%" class="p-0">
                    <ngx-skeleton-loader
                      [count]="pageSize"
                      [theme]="{
                  height: '60px',
                  width: '100%',
                  'border-radius': '10px',
                }"
                    />
                  </td>
                </tr>
                } @if(!(selectSvcustomerisLoading | async) &&
                !(selectSvcustomerData|async)?.customers_data?.length){
                <tr>
                  <td colspan="100%">
                    <div class="card p-3">
                      <div class="empty_template text-center my-2">
                        <img
                          class="w-30"
                          src="assets/img/no_data.svg"
                          alt="No Data"
                        />
                        <p class="fs-5 my-2 text-dark">
                          There is no data available
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                } -->
          </tbody>
        </table>
      </div>
      <!-- </div> -->
      <!-- END Business Accounts List -->

      <div
        class="dropdown-divider"
        *ngIf="accountsResponse?.count > pageSize"
      ></div>

      <!-- Pagination -->
      <div
        class="pagination-wrapper"
        *ngIf="accountsResponse?.count > pageSize"
      >
        <ngb-pagination
          class="ngb-pagination"
          [collectionSize]="accountsResponse?.count"
          [(page)]="page"
          [pageSize]="pageSize"
          aria-label="pagination"
          [rotate]="true"
          [maxSize]="3"
          (pageChange)="onPageEvent($event)"
          [ellipses]="true"
          [boundaryLinks]="false"
        >
          <ng-template ngbPaginationPrevious
            ><i class="bi-chevron-left"></i>Previous</ng-template
          >
          <ng-template ngbPaginationNext
            >Next<i class="bi-chevron-right"></i
          ></ng-template>
        </ngb-pagination>
      </div>
      <!-- END Pagination -->
    </div>
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END Business Accounts List Template -->

<ng-template #nodata>
  <!-- Content -->
  <div class="card p-3 mt-2">
    <!-- template Content -->
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="No Data" />
      <p class="fs-5 my-2 text-dark">There is no data available</p>
    </div>
    <!-- END template Content -->
  </div>
  <!-- END Content -->
</ng-template>

<!-- Create Business Account template -->
<ng-template #createBusinessAccountTemp>
  <!-- Header -->
  <div class="card" *ngIf="!isAccountCreationSuccessful">
    <div class="top_header_options justify-content-start gap-2">
      <button class="btn btn-sm btn-icon btn-light" (click)="onCancelCreate()">
        <em class="bi bi-chevron-left"></em>
      </button>

      <h3 class="main_title">Request for New WABA Number</h3>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card mt-2">
    <!-- Creation of Business Account  -->
    <ng-container [ngSwitch]="isAccountCreationSuccessful">
      <ng-container *ngSwitchCase="false">
        <div class="row m-0 justify-content-between">
          <div class="col-lg-8">
            <div class="setup_form_sec">
              <!-- Request Type Selection -->
              <div class="row">
                <mat-radio-group
                  aria-label="Select an option"
                  [(ngModel)]="selectedRequestType"
                  (ngModelChange)="onSelectionRequestType($event)"
                >
                  <mat-radio-button value="onboarding" checked
                    >Onboarding</mat-radio-button
                  >
                  <mat-radio-button value="migrate">Migrate</mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- END Request Type Selection -->

              <mat-divider class="my-2"></mat-divider>

              <ng-container [ngSwitch]="selectedRequestType">
                <!-- Onboarding Business Account -->
                <ng-container *ngSwitchCase="'Onboarding'">
                  <form
                    [formGroup]="onBoardingForm"
                    (ngSubmit)="onSuccessFulAccountCreation(onBoardingForm)"
                  >
                    <!-- BSP Selection -->
                    <div class="form-group">
                      <label class="input-label" for="bspSelection"
                        >Select BSP
                        <span
                          class="text-danger"
                          matTooltip="BSP Selection is mandatory"
                          >*</span
                        >
                      </label>
                      <ng-select
                        formControlName="bsp"
                        id="bspSelection"
                        class="selection"
                        [items]="bspList"
                        [closeOnSelect]="true"
                        [clearable]="false"
                        [searchable]="false"
                        placeholder="Select BSP's"
                      >
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                          <img [src]="item.bspUrl" [alt]="item.bspName" />
                        </ng-template>
                      </ng-select>

                      <!-- BSP Error -->
                      <div class="invalid-feedback">
                        <div
                          *ngIf="
                            onBoardingFC['bsp'].touched ||
                            onBoardingFC['bsp'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['bsp'].value === '' ||
                                onBoardingFC['bsp'].value === null;
                              else fcInvalid
                            "
                            >Please select a BSP.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['bsp'].errors"
                              >Please select a valid BSP.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END BSP Error -->
                    </div>
                    <!-- END BSP Selection -->

                    <!-- Avatar Upload -->
                    <div class="form-group">
                      <label class="input-label" for="avatarImg"
                        >Add Business Logo<span class="text-danger"
                          >*</span
                        ></label
                      >

                      <div
                        class="avatar avatar-xl avatar-circle avatar-uploader"
                      >
                        <ng-template
                          [ngIf]="!onBoardingFC['profileUrl'].value"
                          [ngIfElse]="profileUrlTemp"
                        >
                          <img
                            class="avatar-img"
                            src="assets/img/customer/default_avatar.jpg"
                            alt="Default Avatar"
                          />
                          <label for="upload_input">
                            <input
                              type="file"
                              accept="image/*"
                              class="d-none"
                              id="avatarImg"
                              (change)="
                                onAvatarUpload('createBusinessAccount', $event)
                              "
                              id="upload_input"
                            />
                            <i class="bi-camera avatar_icon"></i>
                          </label>
                        </ng-template>
                        <ng-template #profileUrlTemp>
                          <img
                            class="avatar-img"
                            *ngIf="
                              onBoardingFC['profileUrl'].value &&
                              onBoardingFC['profileUrl'].value !== 'undefined'
                            "
                            [src]="onBoardingFC['profileUrl'].value"
                            alt="profile Avatar"
                          />
                          <i
                            class="bi-trash-fill avatar_icon"
                            (click)="onAvatarRemove('createBusinessAccount')"
                            (keyup)="onAvatarRemove('createBusinessAccount')"
                          ></i>
                        </ng-template>
                      </div>
                    </div>
                    <!-- END Avatar Upload -->

                    <!-- Business Display Name -->
                    <div class="form-group">
                      <label class="input-label" for="displayName"
                        >Business Display Name
                        <span
                          class="text-danger"
                          matTooltip="Business Display Name is mandatory"
                          >*</span
                        >
                        <em
                          class="bi-info-circle"
                          matTooltip="Your Display name is verified by Meta as per display guidelines
                                      Display Guidelines"
                        ></em>
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Display Name Here"
                        formControlName="business_name"
                      />

                      <!-- Business Name Error -->
                      <div class="invalid-feedback">
                        <div
                          *ngIf="
                            onBoardingFC['business_name'].touched ||
                            onBoardingFC['business_name'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['business_name'].value === '' ||
                                onBoardingFC['business_name'].value === null;
                              else fcInvalid
                            "
                            >Please enter your business display
                            name.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['business_name'].errors"
                              >Please enter a valid business display name.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Business Name Error -->
                    </div>
                    <!-- END Business Display Name -->

                    <!-- Business WhatsApp Number -->
                    <div class="form-group">
                      <label class="input-label" for="whatsappNo"
                        >WhatsApp Number
                        <span
                          class="text-danger"
                          matTooltip="WhatsApp Number is mandatory"
                          >*</span
                        ></label
                      >

                      <div class="d-flex gap-1">
                        <div class="dial_code">
                          <!-- Dial Code Select -->
                          <ng-select
                            formControlName="country_code"
                            id="whatsappNo"
                            placeholder="Select Country Code"
                            bindLabel="dialCode"
                            [items]="countries"
                            [clearable]="false"
                            [searchable]="true"
                            [closeOnSelect]="true"
                            dropdownPosition="bottom"
                          >
                            <ng-template ng-option-tmp let-item="item">
                              <em class="bi-phone"></em> +{{ item.dialCode }}
                              {{ item.name }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <em class="bi-phone gd_icon"></em> +{{
                                item.dialCode
                              }}
                              {{ item.name }}
                            </ng-template>
                          </ng-select>

                          <!-- country_code Error -->
                          <div class="invalid-feedback">
                            <div
                              *ngIf="
                                onBoardingFC['country_code'].touched ||
                                onBoardingFC['country_code'].dirty
                              "
                            >
                              <ng-container
                                *ngIf="
                                  onBoardingFC['country_code'].value === '' ||
                                    onBoardingFC['country_code'].value === null;
                                  else fcInvalid
                                "
                                >Please select a Country Code.</ng-container
                              >
                              <ng-template #fcInvalid>
                                <span
                                  *ngIf="onBoardingFC['country_code'].errors"
                                  >Please select a valid Country Code.</span
                                >
                              </ng-template>
                            </div>
                          </div>
                          <!-- END country_code Error -->
                          <!-- END Dial Code Select -->
                        </div>

                        <div class="whatsapp_no w-100">
                          <!-- WhatsApp Number Input -->
                          <input
                            class="form-control d-block"
                            type="tel"
                            inputmode="numeric"
                            placeholder="Enter whatsapp number"
                            formControlName="business_no"
                            (keydown.arrowup)="(false)"
                            (keydown.arrowdown)="(false)"
                          />
                          <!-- END WhatsApp Number Input -->

                          <!-- WhatsApp Number Error -->
                          <div class="invalid-feedback">
                            <div
                              *ngIf="
                                onBoardingFC['business_no'].touched ||
                                onBoardingFC['business_no'].dirty
                              "
                            >
                              <ng-container
                                *ngIf="
                                  onBoardingFC['business_no'].value === '' ||
                                    onBoardingFC['business_no'].value === null;
                                  else fcInvalid
                                "
                              >
                                Please enter your contact number.
                              </ng-container>
                              <ng-template #fcInvalid>
                                <span
                                  *ngIf="onBoardingFC['business_no'].errors"
                                >
                                  Please enter a valid contact number.
                                </span>
                              </ng-template>
                            </div>
                          </div>
                          <!-- END WhatsApp Number Error -->
                        </div>
                      </div>
                    </div>
                    <!-- END Business WhatsApp Number -->

                    <!-- Business Description -->
                    <div class="form-group">
                      <label class="input-label" for="businessDesc"
                        >Business Description
                        <span
                          class="text-danger"
                          matTooltip="Business description is mandatory"
                          >*</span
                        >
                      </label>

                      <textarea
                        formControlName="business_desc"
                        type="text"
                        rows="4"
                        id="businessDesc"
                        class="form-control"
                        placeholder="Enter Here"
                      ></textarea>

                      <!-- Business Description Error -->
                      <div class="invalid-feedback">
                        <div
                          *ngIf="
                            onBoardingFC['business_desc'].touched ||
                            onBoardingFC['business_desc'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['business_desc'].value === '' ||
                              onBoardingFC['business_desc'].value === null
                            "
                          >
                            Please enter your Business Description.
                          </ng-container>
                        </div>
                      </div>
                      <!-- END Business Description Error -->
                    </div>
                    <!-- END Business Description -->

                    <!-- Facebook Business Manger ID -->
                    <div class="form-group">
                      <label class="input-label" for="fbMID"
                        >Facebook Business Manger ID
                        <span
                          class="text-danger"
                          matTooltip="Facebook business manger ID is mandatory"
                          >*</span
                        >
                      </label>
                      <input
                        class="form-control"
                        id="fbMID"
                        type="text"
                        placeholder="Enter Here"
                        formControlName="manager_id"
                      />

                      <!-- Facebook Business Manger ID Error -->
                      <div class="invalid-feedback mb-4">
                        <div
                          *ngIf="
                            onBoardingFC['manager_id'].touched ||
                            onBoardingFC['manager_id'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['manager_id'].value === '' ||
                                onBoardingFC['manager_id'].value === null;
                              else fcInvalid
                            "
                            >Please enter your Facebook Business Manger
                            ID.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['manager_id'].errors"
                              >Please enter a valid Facebook Business Manger
                              ID.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Facebook Business Manger ID Error -->
                    </div>
                    <!-- END Facebook Business Manger ID -->

                    <!-- Name associated with Facebook Business Manager ID -->
                    <div class="form-group">
                      <label class="input-label" for="fbMIDname"
                        >Name associated with Facebook Business Manager ID
                        <span class="text-danger" matTooltip="Name is mandatory"
                          >*</span
                        >
                      </label>
                      <input
                        formControlName="associated_name"
                        id="fbMIDname"
                        class="form-control"
                        type="text"
                        placeholder="Enter Here"
                      />
                      <!-- Name associated with Facebook Business Manager ID Error -->
                      <div class="invalid-feedback mb-4">
                        <div
                          *ngIf="
                            onBoardingFC['associated_name'].touched ||
                            onBoardingFC['associated_name'].dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              onBoardingFC['associated_name'].value === '' ||
                                onBoardingFC['associated_name'].value === null;
                              else fcInvalid
                            "
                            >Please enter your Name associated with Facebook
                            Business Manager ID.</ng-container
                          >
                          <ng-template #fcInvalid>
                            <span *ngIf="onBoardingFC['associated_name'].errors"
                              >Please enter a valid Name associated with
                              Facebook Business Manager ID.</span
                            >
                          </ng-template>
                        </div>
                      </div>
                      <!-- END Name associated with Facebook Business Manager ID Error -->
                    </div>
                    <!-- END Name associated with Facebook Business Manager ID -->

                    <!-- divider -->
                    <mat-divider class="my-2"></mat-divider>
                    <!-- END divider -->

                    <!-- Save & Cancel Buttons -->
                    <div class="form_action_btns">
                      <div class="options_left"></div>
                      <div class="options_right">
                        <!-- Cancel Button -->
                        <button class="btn btn-outline-dark" type="button">
                          Cancel
                        </button>
                        <!-- END Cancel Button -->

                        <!-- Request Button -->
                        <button
                          class="btn btn-gradient"
                          type="button"
                          [disabled]="onBoardingForm.invalid"
                        >
                          Request
                          <em class="bi-chevron-right"></em>
                        </button>
                        <!-- END Request Button -->
                      </div>
                    </div>
                    <!-- END Save & Cancel Buttons -->
                  </form>
                </ng-container>
                <!-- END Onboarding Business Account -->

                <!-- Migrate Business Account -->
                <ng-container *ngSwitchCase="'Migrate'">
                  <form
                    [formGroup]="migrateForm"
                    (ngSubmit)="onSuccessFulAccountCreation(migrateForm)"
                  >
                    <!-- Upload Documents Dropzone -->
                    <h4 class="mb-3">Upload Documents</h4>
                    <!-- Csv/Excel Drag-n-Drop Area -->
                    <fieldset
                      class="media_upload_dropZone d-flex align-items-center justify-content-center gap-4"
                    >
                      <legend class="visually-hidden">Image uploader</legend>
                      <input
                        class="upload_image"
                        type="file"
                        accept="text/csv"
                        (change)="
                          onSelect($event); $any($event.target).value = ''
                        "
                      />
                      <div class="d-flex align-items-center gap-3">
                        <div class="file_upload_icon">
                          <em class="bi bi-upload fs-1 text-muted"></em>
                        </div>
                        <div class="files_supported text-center">
                          <h3 class="text-dark">
                            Drag and drop file or
                            <span class="text-primary text-decoration-underline"
                              >Browse</span
                            >
                          </h3>
                          <p>
                            Upload<strong class="mx-2"
                              >"2 Factor Authentication: Disabled"</strong
                            >Screenshot
                          </p>
                        </div>
                      </div>
                    </fieldset>
                    <!-- END Csv/Excel Drag-n-Drop Area -->

                    <!-- Uploaded Files -->
                    <div class="upload_gallery my-2" *ngIf="file">
                      <div class="uploaded_image">
                        <img src="assets/img/nuacem.svg" alt="Nuacem" />
                        <em
                          class="bi bi-x-circle-fill img_close"
                          (click)="file = null"
                          (keyup)="file = null"
                        >
                        </em>
                      </div>
                      <span>{{ file.name }}</span>
                    </div>
                    <!-- END Uploaded Files -->
                    <!-- Divider -->
                    <mat-divider class="my-3"></mat-divider>
                    <!-- END Divider -->
                    <div class="row justify-content-end mx-0">
                      <button
                        type="submit"
                        class="col-auto btn btn-gradient text-white px-4"
                      >
                        Add Now
                      </button>
                    </div>
                  </form>
                </ng-container>
                <!-- END Migrate Business Account -->
              </ng-container>
            </div>
          </div>

          <!-- WhatsApp Setup Preview -->
          <div class="col p-0">
            <div class="whatsapp_preview">
              <div class="box">
                <app-whatsapp-preview></app-whatsapp-preview>
              </div>
            </div>
          </div>
          <!-- END WhatsApp Setup Preview -->
        </div>
      </ng-container>
      <!-- END Creation of Business Account  -->

      <!-- Successfully Created Business Account  -->
      <ng-container *ngSwitchCase="true">
        <div class="py-10 my-10 m-4">
          <div class="text-center py-7 my-7">
            <img
              class="mb-3"
              src="assets/img/success.svg"
              alt="New Business Account"
            />
            <p class="fs-4 fw-bold">
              Your request has been submitted successfully
            </p>
            <button
              type="button"
              class="btn btn-lg btn-gradient btn-primary"
              (click)="onGoToBusinessNumber()"
            >
              <div class="d-flex align-items-center">
                <em class="bi bi-plus me-2"></em>
                Go to business number
              </div>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- END Successfully Created Business Account -->
  </div>
  <!-- Template Content -->
</ng-template>
<!-- END Create Business Account template -->

<!-- View Business Account Template -->
<ng-template #viewBusinessAccountsTemp>
  <!-- Header -->
  <div class="card">
    <div class="top_header_options">
      <div class="d-flex align-items-center gap-2">
        @if (sso &&
        loggedInUserDetails.account.account_type.toUpperCase()!=="ROOT"){
        <!-- Goto business accounts Listing page -->
        <button class="btn btn-sm btn-icon btn-light" (click)="gotoListing()">
          <em class="bi bi-chevron-left"></em>
        </button>
        <!-- END Goto business accounts Listing page -->
        }
        <div class="bact_view_details">
          <div class="details d-flex align-items-center gap-2">
            <div class="business_avatar">
              <div class="avatar avatar-circle avatar-lg">
                <img
                  class="avatar-img"
                  [src]="businessAccountsData.profile_pic"
                  [alt]="businessAccountsData.business_name"
                />
              </div>
            </div>
            <div class="business_info">
              <h3 class="d-flex align-items-center gap-1 mb-2">
                {{ businessAccountsData.waba_number }}
                <img src="assets/img/verified.svg" alt="Verified" />
              </h3>
              <ul
                class="list-inline-flex list-unstyled list-divider mb-0 text-small text-body text-capitalize"
              >
                <li class="list-inline-item">
                  {{ businessAccountsData.business_name }}
                </li>
                <li class="list-inline-item">
                  WABA ID :
                  {{ businessAccountsData.waba_id }}
                </li>
              </ul>
              <!-- End Row -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <div class="card card-vh-100 mt-2">
    <div class="card-body p-2">
      <div class="wabaid_details">
        <div class="nav_tabs">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="active"
            class="nav-tabs mb-4"
            (navChange)="onNavChange($event)"
          >
            <!-- WABA Details -->
            <li [ngbNavItem]="1">
              <button ngbNavLink>WABA Details</button>
              <ng-template ngbNavContent>
                <ng-container [ngSwitch]="isEditMode">
                  <!-- View Business Account -->
                  <ng-container *ngSwitchCase="false">
                    <!-- WABA Details -->
                    <div class="wabaid_details">
                      <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                        <!-- WABA ID -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA ID</span>
                            <h5 class="m-0">
                              {{ businessAccountsData.waba_id }}
                            </h5>
                          </div>
                        </div>
                        <!-- END WABA ID -->

                        <!-- WABA Status -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA Status</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.waba_status?.toLowerCase() ===
                                'connected'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{ businessAccountsData.waba_status | titlecase }}
                            </h5>
                          </div>
                        </div>
                        <!-- END WABA Status -->

                        <!-- WABA Quality -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">WABA Quality</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.waba_quality.toLowerCase() ===
                                'high'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{
                                businessAccountsData.waba_quality | titlecase
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- ENDWABA Quality -->

                        <!-- END Number Quality -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Number Quality</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.number_quality.toLowerCase() ===
                                'high'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{
                                businessAccountsData.number_quality | titlecase
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- Number Quality -->

                        <!-- Number Status -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Number Status</span>
                            <h5
                              class="m-0"
                              [ngClass]="
                                businessAccountsData.number_status.toLowerCase() ===
                                'connected'
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              {{
                                businessAccountsData.number_status | titlecase
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- END Number Status -->

                        <!-- Messaging Limit -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Messaging Limit</span>
                            <h5 class="m-0">
                              {{ businessAccountsData.messaging_limit }}
                              <em
                                class="bi-info-circle ms-2"
                                matTooltip="Messaging limits determine the daily quota for sending template messages to unique users for your business."
                                matTooltipPosition="above"
                              ></em>
                            </h5>
                          </div>
                        </div>
                        <!-- END Messaging Limit -->
                      </div>
                    </div>
                    <!-- END WABA Details -->

                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->

                    <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                      <!-- Traffic/Volume -->
                      <div class="col-4">
                        <div class="item_detail">
                          <span class="label">Committed Volume</span>
                          <h5 id="traffic">
                            {{ businessAccountsData.traffic_volume }}
                            <em
                              class="bi-info-circle"
                              matTooltip="Committed volume is the volume promised by your organization that shall be utilized through this WABA ID, you may send more messages than committed volume, but will be restricted on the TIER limit of the WABA ID"
                            ></em>
                          </h5>
                        </div>
                      </div>
                      <!-- END Traffic/Volume -->

                      <!-- Billing Type -->
                      <div class="col-4">
                        <div class="item_detail">
                          <span class="label">Billing Type</span>
                          <h5 id="traffic">
                            {{ businessAccountsData.billing_type | titlecase }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Billing Type -->

                      <!-- Service Account Name -->
                      <div class="col-4">
                        <div class="item_detail">
                          <span class="label">Service Account Name</span>
                          <h5 id="traffic">
                            {{
                              businessAccountsData?.service_account_name
                                | titlecase
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- Service Account Name Type -->
                    </div>

                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->

                    <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                      <!-- Avatar -->
                      <div class="col">
                        <div class="business_avatar d-flex flex-column">
                          <span class="label">Display Picture</span>

                          <!-- Options to save/cancel the uploaded image -->
                          <div class="pic_options" *ngIf="businessprofile_pic">
                            <button
                              class="btn btn-xs btn-primary"
                              (click)="confBusinessProfile_pic()"
                              (keyup)="confBusinessProfile_pic()"
                            >
                              Save
                            </button>
                            <button
                              class="btn btn-xs btn-outline-danger"
                              (click)="removeUploadPic()"
                              (keyup)="removeUploadPic()"
                            >
                              Cancel
                            </button>
                          </div>
                          <!-- Options to save/cancel the uploaded image -->

                          <div
                            class="avatar avatar-circle avatar-lg avatar-uploader profileImg"
                          >
                            <img
                              class="avatar-img"
                              [src]="
                                businessprofile_pic
                                  ? preview
                                  : businessAccountsData.profile_pic
                              "
                              [alt]="businessAccountsData.business_name"
                            />

                            <span class="avatar-uploader-trigger">
                              <em
                                *ngIf="!businessprofile_pic"
                                class="bi bi-pencil-fill avatar-uploader-icon shadow-soft avatar_icon"
                                matTooltip="Change Avatar"
                                (click)="fileinput.click()"
                                (keyup)="fileinput.click()"
                              ></em>
                              <input
                                type="file"
                                accept="image/*"
                                class="d-none"
                                id="mp_upload_input"
                                name="userImage"
                                (change)="onSelect($event)"
                                (click)="clearInputValue($event)"
                                #fileinput
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- END Avatar -->

                      <!-- Entity Name -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Entity Name</span>
                          <h5 class="m-0" id="asctName">
                            {{
                              businessAccountsData.business_name
                                ? businessAccountsData.business_name
                                : "N/A"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Entity Name -->

                      <!-- Business Description -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Business Description</span>
                          <app-read-more
                            [content]="
                              businessAccountsData.business_description
                                ? businessAccountsData.business_description
                                : 'N / A'
                            "
                            [limit]="300"
                            [completeWords]="true"
                          >
                          </app-read-more>
                        </div>
                      </div>
                      <!-- END Business Description -->

                      <!-- About Business -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">About</span>
                          <app-read-more
                            [content]="
                              businessAccountsData.about
                                ? businessAccountsData.about
                                : 'N / A'
                            "
                            [limit]="300"
                            [completeWords]="true"
                          >
                          </app-read-more>
                        </div>
                      </div>
                      <!-- END About Business -->

                      <!-- Business Profiles -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Business Profiles</span>
                          <span>
                            <h5 class="m-0">
                              {{ businessAccountsData.business_name }}
                            </h5>
                            <p class="m-0">
                              {{ businessAccountsData.waba_number }}
                            </p>
                          </span>
                        </div>
                      </div>
                      <!-- END Business Profiles -->

                      <!-- BSP -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">BSP</span>
                          <h5>
                            {{
                              businessAccountsData.bsp_name === "RML"
                                ? "Routemobile"
                                : businessAccountsData.bsp_name
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END BSP -->

                      <!-- Channel -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Channel</span>
                          <div
                            class="d-block"
                            *ngIf="businessAccountsData.channel_name === 'WABA'"
                          >
                            <img
                              class="w-10"
                              src="assets/img/whatsapp.svg"
                              [alt]="businessAccountsData.channel_name"
                            />
                            <span class="ms-2">Whatsapp</span>
                          </div>
                        </div>
                      </div>
                      <!-- END Channel -->

                      <!-- Facebook Manager ID -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Facebook Manager ID</span>
                          <h5 class="m-0">
                            {{ businessAccountsData.facebook_manager_id }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Facebook Manager ID -->

                      <!-- Callback URL -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Callback URL</span>
                          <h6 class="m-0 text-decoration-underline">
                            {{ businessAccountsData.callback_url }}
                          </h6>
                        </div>
                      </div>
                      <!-- END Callback URL -->

                      <!-- Created on -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Created on</span>
                          <h5 class="m-0">
                            {{
                              businessAccountsData.created_at
                                | date : "longDate"
                            }}
                          </h5>
                        </div>
                      </div>
                      <!-- END Created on -->

                      <!-- Credentials -->
                      <div class="col">
                        <div class="authorization">
                          <span class="label">Credentials</span>
                          <h5 id="auth_type">
                            Username :
                            {{
                              AuthDetails(
                                businessAccountsData.credential_meta,
                                "username"
                              )
                            }}
                          </h5>
                          <h5>
                            Password :
                            <span>
                              <ng-container *ngIf="!isCopied; else pwdVisible">
                                {{
                                  AuthDetails(
                                    businessAccountsData.credential_meta,
                                    "password"
                                  ) | maskText
                                }}
                              </ng-container>
                              <ng-template #pwdVisible>
                                {{
                                  AuthDetails(
                                    businessAccountsData.credential_meta,
                                    "password"
                                  )
                                }}
                              </ng-template>
                            </span>

                            <em
                              class="ms-1 fs-6 pointer"
                              [ngClass]="{
                                'bi-eye-fill': !isCopied,
                                'bi-eye-slash-fill': isCopied
                              }"
                              (click)="copyCode()"
                              (keyup)="copyCode()"
                            >
                            </em>
                          </h5>
                        </div>
                      </div>
                      <!-- END Credentials -->

                      <!-- Liveagent count on -->
                      <div class="col">
                        <div class="item_detail">
                          <span class="label">Live Agent count</span>
                          <h5 class="m-0">
                            {{ businessAccountsData?.live_agent_count }}
                          </h5>
                        </div>
                      </div>
                      <!-- END  Liveagent count -->
                    </div>

                    <!-- Divider -->
                    <mat-divider class="my-4"></mat-divider>
                    <!-- END Divider -->

                    <!-- Webhook details -->
                    <div class="d-none">
                      <h3 class="mb-4">Webhook Details</h3>
                      <div class="row row-cols-lg-3 row-cols-md-2 row-gap-4">
                        <!-- Delivery URL -->
                        <div class="col">
                          <div class="item_detail">
                            <span class="label">Delivery URL</span>
                            <h6 class="m-0 text-decoration-underline">
                              nb5.botjet.ai/whatsappbync/generic/8831b44c-f3fe-74ed-2ca3-486b0ed869b8
                            </h6>
                          </div>
                        </div>
                        <!-- END Delivery URL -->

                        <!-- Delivery Header -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Delivery Header</div>
                            <h5 class="m-0">
                              0e5ca03ac23da78614e4e6ad917712f3
                            </h5>
                          </div>
                        </div>
                        <!-- END Delivery Header -->

                        <!-- Incoming webhook URL -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Incoming webhook URL</div>
                            <h6 class="m-0 text-decoration-underline">
                              n5t.convojet.ai.whatsapp/genric/87681b44c
                            </h6>
                          </div>
                        </div>
                        <!-- END Incoming webhook URL -->

                        <!-- Incoming webhiik header -->
                        <div class="col">
                          <div class="item_detail">
                            <div class="label">Incoming webhook header</div>
                            <h5 class="m-0">null</h5>
                          </div>
                        </div>
                        <!-- END Incoming webhiik header -->
                      </div>
                    </div>
                    <!-- END Webhook details -->

                    <div class="row d-none">
                      <!-- Edit Button -->
                      <div class="col-12 text-end">
                        <button
                          type="button"
                          class="btn btn-gradient"
                          (click)="onBusinessAccountEditMode()"
                          *ngIf="
                            permissions.waba_business_account_management
                              .can_update
                          "
                        >
                          Edit
                        </button>
                      </div>
                      <!-- END Edit Button -->
                    </div>
                  </ng-container>
                  <!-- END View Business Account -->

                  <!-- Edit Business Account  -->
                  <ng-container *ngSwitchCase="true">
                    <form [formGroup]="editBusinessAccountForm">
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <!-- Avatar Upload -->
                          <div class="business_logo d-flex flex-column mb-4">
                            <label for="avatarImg">Display Picture</label>
                            <!-- Business Profile Avatar Upload -->
                            <div
                              class="avatar avatar-circle avatar-xl avatar-uploader businssProfileImg"
                              id="avatarImg"
                            >
                              <ng-template
                                [ngIf]="!imgURL"
                                [ngIfElse]="profileIconTemp"
                              >
                                <img
                                  class="avatar-img"
                                  src="assets/img/customer/default_avatar.jpg"
                                  alt="User Avatar"
                                />
                                <label for="mp_upload_input" *ngIf="!isEdited">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    class="d-none"
                                    id="mp_upload_input"
                                    (change)="
                                      onAvatarUpload(
                                        'editBusinessAccount',
                                        $event
                                      )
                                    "
                                  />
                                  <i class="bi-camera-fill avatar_icon"></i>
                                </label>
                              </ng-template>
                              <ng-template #profileIconTemp>
                                <img
                                  class="avatar-img"
                                  *ngIf="
                                    bpFC['profileImg'].value &&
                                    bpFC['profileImg'].value !== 'undefined'
                                  "
                                  [src]="allBusinessSettingsData.profileUrl"
                                  alt="profile Avatar"
                                />
                                <em
                                  class="bi-trash-fill avatar_icon"
                                  (click)="
                                    onAvatarRemove('editBusinessAccount')
                                  "
                                  (keyup)="
                                    onAvatarRemove('editBusinessAccount')
                                  "
                                ></em>
                              </ng-template>
                            </div>
                            <!-- END Business Profile Avatar Upload -->
                          </div>
                          <!-- END Avatar Upload -->

                          <!-- Display Name -->
                          <div class="form-group">
                            <label class="input-label" for="displayName"
                              >Display Name
                              <span
                                class="text-danger"
                                matTooltip="Display Name is mandatory"
                                >*</span
                              >
                            </label>
                            <input
                              class="form-control"
                              id="displayName"
                              type="text"
                              placeholder="Enter display name"
                              formControlName="display_name"
                            />
                            <!-- Dispaly Name Error -->
                            <div class="invalid-feedback">
                              <div
                                *ngIf="
                                  editBAFC['display_name'].touched ||
                                  editBAFC['display_name'].dirty
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    editBAFC['display_name'].value === '' ||
                                      editBAFC['display_name'].value === null;
                                    else fcInvalid
                                  "
                                  >Display name can not be empty.
                                </ng-container>
                                <ng-template #fcInvalid>
                                  <span *ngIf="editBAFC['display_name'].errors">
                                    Please enter a valid display name.
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <!-- END DIsplay Name Error -->
                          </div>
                          <!-- END Display Name -->

                          <!-- Business Number -->
                          <div class="form-group">
                            <label class="input-label" for="businessNo"
                              >Business Number
                              <span
                                class="text-danger"
                                matTooltip="Business Number is mandatory"
                                >*</span
                              >
                            </label>
                            <input
                              class="form-control"
                              id="businessNo"
                              type="tel"
                              placeholder="Enter business number"
                              formControlName="business_no"
                              (keydown.arrowup)="(false)"
                              (keydown.arrowdown)="(false)"
                            />
                            <!-- Business Number Error -->
                            <div class="invalid-feedback">
                              <div
                                *ngIf="
                                  editBAFC['business_no'].touched ||
                                  editBAFC['business_no'].dirty
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    editBAFC['business_no'].value === '' ||
                                      editBAFC['business_no'].value === null;
                                    else fcInvalid
                                  "
                                  >Business number can not be empty.
                                </ng-container>
                                <ng-template #fcInvalid>
                                  <span *ngIf="editBAFC['business_no'].errors">
                                    Please enter a valid Business number.
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <!-- END Business Number Error -->
                          </div>
                          <!-- END Business Number -->
                        </div>

                        <div class="col">
                          <!-- Business Description -->
                          <div class="form-group">
                            <label class="input-label" for="businessDesc"
                              >Business Description
                              <span
                                class="text-danger"
                                matTooltip="Business Description is mandatory"
                                >*</span
                              >
                            </label>
                            <textarea
                              class="form-control h-100"
                              type="text"
                              id="businessDesc"
                              rows="4"
                              placeholder="Enter Business Description"
                              aria-label="Enter Business Description"
                              formControlName="business_desc"
                            ></textarea>
                            <!-- Business Number Error -->
                            <div
                              *ngIf="
                                editBAFC['business_desc'].touched ||
                                editBAFC['business_desc'].dirty
                              "
                            >
                              <div class="invalid-feedback">
                                <ng-container
                                  *ngIf="
                                    editBAFC['business_desc'].value === '' ||
                                      editBAFC['business_desc'].value === null;
                                    else fcInvalid
                                  "
                                  >Business description can not be
                                  empty.</ng-container
                                >
                                <ng-template #fcInvalid>
                                  <span
                                    *ngIf="editBAFC['business_desc'].errors"
                                  >
                                    Please enter a valid business
                                    description.</span
                                  >
                                </ng-template>
                              </div>
                            </div>
                            <!-- END Business Number Error -->
                          </div>
                          <!-- END Business Description -->
                        </div>

                        <!-- Divider -->
                        <mat-divider class="my-4"></mat-divider>
                        <!-- END Divider -->

                        <div class="form_action_btns">
                          <div class="options_left"></div>
                          <div class="options_right">
                            <!-- Cancel Button -->
                            <button
                              type="button"
                              class="btn btn-outline-dark"
                              (click)="isEditMode = false"
                            >
                              Cancel
                            </button>
                            <!-- END Cancel Button -->

                            <!-- Save Button -->
                            <button type="submit" class="btn btn-gradient">
                              Save
                            </button>
                            <!-- END Save Button -->
                          </div>
                        </div>
                      </div>
                    </form>
                  </ng-container>
                  <!-- END Edit Business Account  -->
                </ng-container>
              </ng-template>
            </li>
            <!-- END WABA Details -->
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Template Content -->
</ng-template>
<!-- END View Business Account Template -->
