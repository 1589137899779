import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { skeletonData } from 'src/app/appdata/analytics.data';
import {
  ChannelCredentials,
  RbacPolicies,
  SsoSAData,
} from 'src/app/appdata/auth.model';
import { CountryData } from 'src/app/appdata/countries.data';
import {
  AllBusinessSettings,
  allBusinessSettings,
  BSP,
  bsp,
  ServiceAccountParams,
  SsoCustomerAndWalletparams,
  SvcustomersResponse,
  SvwalletsResponse,
  WabaID,
  wabaid,
} from 'src/app/appdata/shared.data';
import * as dashboardactions from 'src/app/customer/dashboard/store/dashboard.actions';
import { selectChannelCredentials } from 'src/app/customer/dashboard/store/dashboard.selectors';
import { CustomValidators } from 'src/app/services/CustomValidators';
import { SharedService } from 'src/app/services/shared.service';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { environment } from 'src/environments/environment';
import {
  getFiletrsData,
  GetSvCustomers,
  GetSvWallets,
} from './store/serviceaccount.actions';
import {
  selectSvcustomerisLoading,
  selectSvcustomersState,
} from './store/serviceaccount.reducers';
import {
  selectSvwalletsdata,
  selectSvwalletsisLoading,
  selectSvWalletsState,
} from './store/serviceaccountwallets.reducers';

export interface SliderValues {
  color: string;
  checked: boolean;
  disabled: boolean;
  labelPosition: 'before' | 'after';
  status: string[];
}

@Component({
  selector: 'app-service-accounts',
  templateUrl: './service-accounts.component.html',
  styleUrls: ['./service-accounts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceAccountsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isAccountCreationSuccessful: boolean = false;
  editBusinessAccountForm: FormGroup;
  onBoardingForm: FormGroup;
  migrateForm: FormGroup;
  ssoSuperAdminFilters: FormGroup;
  displayTemplate = new BehaviorSubject<TemplateRef<string>>(null);
  businessAccountsList: WabaID[] = wabaid;
  businessAccountsData: any;
  allBusinessSettingsData: AllBusinessSettings = allBusinessSettings;
  bspList: BSP[] = bsp;
  countries: Array<any> = [];
  selectedDialCode: any;
  searchData: string = '';
  active = 1;
  page = 1;
  pageSize = 10;
  isEditMode: boolean = false;
  isEdited: boolean = false;
  businessprofile_pic: boolean = false;
  fileUploadSuccess: boolean = false;
  imgURL: string;
  imagePath: FileList;
  requestTypes: string[] = ['Onboarding', 'Migrate'];
  selectedRequestType: string;
  file: File;
  selectedBSP: any;
  original_img: string;
  preview: any = '';
  // selectSvcustomerData = this.store.select(selectSvcustomerdata);
  selectSvcustomerisLoading = this.store.select(selectSvcustomerisLoading);
  selectSvwalletsData = this.store.select(selectSvwalletsdata);
  selectSvwalletsisLoading = this.store.select(selectSvwalletsisLoading);
  sliderValue: SliderValues = {
    color: 'accent',
    checked: true,
    disabled: false,
    labelPosition: 'before',
    status: ['Active', 'Inactive'],
  };
  channel_credentails = signal<ChannelCredentials[]>([]);
  accountsResponse: SsoSAData;
  skeletonData = skeletonData;
  permissions: RbacPolicies;
  channelSub: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    businessname: null,
  };
  ssoParams: ServiceAccountParams = {
    account_id: null,
    wallet_mode: null,
    bsp: null,
    sv_customer_id: null,
    wallet_name: null,
    page: 1,
    size: 10,
  };
  ssoCustomerAndWalletparams = SsoCustomerAndWalletparams;
  loggedInUserDetails: any;
  sso: boolean = environment.sso;
  Wallet_Mode = [
    {
      id: 'uw',
      name: 'Unified Wallet',
    },
    {
      id: 'ssw',
      name: 'Single Service Wallet',
    },
  ];

  @ViewChild('noTemplates') private readonly noTemplates: TemplateRef<any>;
  @ViewChild('skeletonTemp') private skeletonTemp: TemplateRef<any>;
  @ViewChild('nodata') private nodata: TemplateRef<any>;
  @ViewChild('allBusinessAccountsTemp')
  private allBusinessAccountsTemp: TemplateRef<any>;
  @ViewChild('createBusinessAccountTemp')
  private createBusinessAccountTemp: TemplateRef<any>;
  @ViewChild('viewBusinessAccountsTemp')
  private viewBusinessAccountsTemp: TemplateRef<any>;
  @ViewChild('dropzoneRef', { static: false }) dropzoneRef: any;
  isCopied: boolean;
  Svcustomerdata: SvcustomersResponse['data'];
  Svwalletsdata: SvwalletsResponse['data'];

  constructor(
    private readonly store: Store,
    private shareservice: SharedService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private cv: CustomValidators,
    private countrydata: CountryData,
    private alertMsg: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  // Life-cycle Hooks
  ngOnInit(): void {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials?.account_id;
        this.account_dtls.bsp_id = value.channel_credentials?.bsp_id;
        // this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials?.channel_name?.toLowerCase();
      }
    });
    this.countries = this.countrydata.CountryDialCodes;
    this.selectedDialCode = this.countries.find((t) => t.dialCode === '91');

    this.selectedRequestType = this.requestTypes[0];

    this.editBusinessAccountForm = this.fb.group({
      profileUrl: [null],
      display_name: [null, Validators.required],
      business_no: [
        null,
        [Validators.required, Validators.pattern(this.cv.bnameRegex)],
      ],
      business_desc: [null, Validators.required],
    });

    this.onBoardingForm = this.fb.group({
      profileUrl: [null],
      bsp: [null, Validators.required],
      country_code: [null, Validators.required],
      business_no: [
        null,
        [Validators.required, Validators.pattern(this.cv.bnameRegex)],
      ],
      business_name: [null, Validators.required],
      business_desc: [null, Validators.required],
      manager_id: [null, Validators.required],
      associated_name: [null, Validators.required],
    });

    this.migrateForm = this.fb.group({
      uploadMedia: [null],
    });

    this.ssoSuperAdminFilters = this.fb.group({
      wallet_mode: null,
      bsp: null,
      sv_customer_id: null,
      wallet_name: null,
    });

    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.permissions = res.loggedInUserDetails.rbac.rbac_policies;
            this.loggedInUserDetails = res.loggedInUserDetails;
            this.selectedBSP = this.bspList[0];
            if (
              !this.router.url.includes('view-service-account') &&
              !this.router.url.includes('dashboard')
            ) {
              this.getBusinessAccounts();
            }
            // this.store.dispatch(
            //   dashboardactions.getChannelCredentials({
            //     payload: this.account_dtls,
            //   })
            // );
          }
        });
      this.store
        .select(selectSvcustomersState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            this.Svcustomerdata = res.Svcustomerdata;
          } else if (res.Svcustomererror) {
            console.log(res.Svcustomererror);
          }
        });

      this.store
        .select(selectSvWalletsState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            this.Svwalletsdata = res.Svwalletsdata;
          } else if (res.Svwalletserror) {
            console.log(res.Svwalletserror);
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.permissions =
              res.loggedInUserDetails.profile.acl.rbac.rbac_policies;
            this.loggedInUserDetails = res.loggedInUserDetails;
            // this.channel_credentails =
            //   res.loggedInUserDetails.account.licenses.license_data;
            this.selectedBSP = this.bspList[0];
            this.getBusinessAccounts();
          }
        });
    }
    this.store
      .select(selectChannelCredentials)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.dashboarddata && res.dashboarddata.status_code === 200) {
          this.accountsResponse = res.dashboarddata.data;
          this.channel_credentails.set(
            res.dashboarddata.data.all_channel_credentials
          );
          this.channel_credentails()?.length > 0
            ? this.displayTemplate.next(this.allBusinessAccountsTemp)
            : this.displayTemplate.next(this.nodata);
        } else if (res.selectedAccountDetails) {
          this.businessAccountsData = res.selectedAccountDetails;
        } else if (res.error) {
          if (res.error && res.error.status_code === 404) {
            this.channel_credentails.set([]);
          }
        }
      });

    // this.activatedRoute.params.subscribe((params: Params) => {
    //   const sel_id = params['id'];
    // })
  }

  getBusinessAccounts() {
    if (environment.sso) {
      if (
        this.loggedInUserDetails.account.account_type.toUpperCase() === 'ROOT'
      ) {
        this.store.dispatch(
          dashboardactions.getServiceAccountsWithParams({
            SA_Params: this.ssoParams,
          })
        );
      } else {
        this.store.dispatch(
          dashboardactions.getChannelCredentials({
            payload: this.account_dtls,
          })
        );
        // this.ssoParams.account_id = this.loggedInUserDetails.account.id;
        // this.store.dispatch(
        //   dashboardactions.getServiceAccountsWithParams({
        //     SA_Params: this.ssoParams,
        //   })
        // );
      }
    } else {
      this.store.dispatch(
        dashboardactions.getChannelCredentials({
          payload: this.account_dtls,
        })
      );
    }
  }

  get bpFC() {
    return this.editBusinessAccountForm.controls;
  }

  ngAfterViewInit(): void {
    if (
      this.router.url.includes('view-service-account') ||
      this.router.url.includes('dashboard')
    ) {
      this.displayTemplate.next(this.viewBusinessAccountsTemp);
    } else {
      this.displayTemplate.next(this.skeletonTemp);
    }
    this.cd.detectChanges();
  }
  // END Life-cycle Hooks

  gotoListing() {
    this.router.navigate([
      'cc',
      this.loggedInUserDetails.profile.id,
      'service-accounts',
    ]);
    //this.getBusinessAccounts();

    // this.channel_credentails.length !== 0
    //   ? this.displayTemplate.next(this.allBusinessAccountsTemp)
    //   : this.displayTemplate.next(this.noTemplates);
    // this.cd.detectChanges();
  }

  // Getters
  get onBoardingFC() {
    return this.onBoardingForm.controls;
  }

  get editBAFC() {
    return this.editBusinessAccountForm.controls;
  }
  // END Getters

  // Methods
  onCreateBusinessAccount() {
    this.displayTemplate.next(this.createBusinessAccountTemp);
  }

  //Method to search
  onSearchNameChange(event: any) {
    if (!event) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.businessname = null;
      this.ssoParams = JSON.parse(JSON.stringify(this.ssoParams));
      if (
        environment.sso &&
        this.loggedInUserDetails.account.account_type.toUpperCase() === 'ROOT'
      ) {
        this.ssoParams.page = 1;
        this.ssoParams.service_account_name = null;
        this.store.dispatch(
          dashboardactions.getServiceAccountsWithParams({
            SA_Params: this.ssoParams,
          })
        );
      } else {
        this.store.dispatch(
          dashboardactions.getChannelCredentials({ payload: this.account_dtls })
        );
      }
    } else {
      this.searchName(event);
    }
  }

  applyFilter() {
    this.store.dispatch(
      dashboardactions.getServiceAccountsWithParams({
        SA_Params: this.ssoParams,
      })
    );
  }

  clearInputValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.value = null;
    }
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.businessname = data.trim();
      console.log(data);
      this.ssoParams = JSON.parse(JSON.stringify(this.ssoParams));
      if (
        environment.sso &&
        this.loggedInUserDetails.account.account_type.toUpperCase() === 'ROOT'
      ) {
        this.ssoParams.page = 1;
        this.ssoParams.service_account_name = data.trim();
        this.store.dispatch(
          dashboardactions.getServiceAccountsWithParams({
            SA_Params: this.ssoParams,
          })
        );
      } else {
        this.store.dispatch(
          dashboardactions.getChannelCredentials({ payload: this.account_dtls })
        );
      }
    }
  }

  onCancelCreate() {
    this.selectedRequestType = this.requestTypes[0];
    this.businessAccountsList.length !== 0
      ? this.displayTemplate.next(this.allBusinessAccountsTemp)
      : this.displayTemplate.next(this.noTemplates);
  }

  onViewBusinessAccount(accdata: any) {
    this.businessAccountsData = accdata;
    this.store.dispatch(
      dashboardactions.chooseBusinesAccount({ data: accdata })
    );
    if (
      environment.sso &&
      this.loggedInUserDetails.account.account_type === 'ROOT'
    ) {
      this.router.navigate([
        this.shareservice.getRoutingUrl(),
        this.loggedInUserDetails.profile.id,
        'dashboard',
      ]);
    } else {
      this.router.navigate([
        'cc',
        this.loggedInUserDetails.profile.id,
        'view-service-account',
      ]);
      //this.displayTemplate.next(this.viewBusinessAccountsTemp);
    }
  }

  onSelectionRequestType(event: string) {
    if (event === 'onboarding') {
      this.selectedRequestType = this.requestTypes[0];
    } else if (event === 'migrate') {
      this.selectedRequestType = this.requestTypes[1];
    }
  }

  onAvatarUpload(data: string, event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files || files.length === 0) return;
    const mimeType = files[0].type;
    if (!mimeType.startsWith('image/')) return;
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      if (data === 'createBusinessAccount') {
        this.onBoardingForm.controls['profileUrl'].setValue(reader.result);
      } else if (data === 'editBusinessAccount') {
        this.editBusinessAccountForm.controls['profileUrl'].setValue(
          reader.result
        );
      }
    };
  }

  onAvatarRemove(data: string) {
    if (data === 'createBusinessAccount') {
      this.onBoardingForm.controls['profileUrl'].setValue(null);
    } else if (data === 'editBusinessAccount') {
      this.editBusinessAccountForm.controls['profileUrl'].setValue(null);
    }
  }

  onSuccessFulAccountCreation(form: FormGroup) {
    this.isAccountCreationSuccessful = true;
  }

  onBusinessAccountEditMode() {
    this.isEditMode = true;
  }

  onGoToBusinessNumber() {
    this.isAccountCreationSuccessful = false;
    this.selectedRequestType = this.requestTypes[0];
    this.displayTemplate.next(this.allBusinessAccountsTemp);
  }

  onSlideToggleChange(event: any) {
    if (event) {
      this.sliderValue.checked = !this.sliderValue.checked;
    }
  }

  removeUploadPic() {
    this.businessAccountsData = JSON.parse(
      JSON.stringify(this.businessAccountsData)
    );
    this.businessAccountsData.profile_pic = this.original_img;
    this.file = null;
    this.businessprofile_pic = false;
  }

  onSelect(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.alertMsg.alertWarning(
          'Warning!',
          "You can't upload documents which are greater than 2MB"
        );
        return;
      } else {
        this.original_img = this.businessAccountsData.profile_pic;

        this.file = event.target.files[0];
        if (this.file) {
          this.businessprofile_pic = true;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.preview = e.target.result;
          };
          reader.readAsDataURL(this.file);
        }
      }
    }
  }

  confBusinessProfile_pic() {
    this.shareservice
      .fileUpload(
        this.file,
        this.account_dtls.account_id,
        this.businessAccountsData.waba_number,
        // this.account_dtls.waba_no,
        this.account_dtls.channel,
        this.loggedInUserDetails.profile.id,
        false
        // this.account_dtls.createdby,
      )
      .subscribe({
        next: (response) => {
          if (response.status_code === 200) {
            this.businessAccountsData = JSON.parse(
              JSON.stringify(this.businessAccountsData)
            );
            this.businessAccountsData.profile_pic = response.data.url;
            this.fileUploadSuccess = true;

            // API call for saving the confimed image upload to media drive
            if (this.fileUploadSuccess) {
              const payload = {
                id: this.account_dtls.bsp_id,
                profile_pic: this.businessAccountsData.profile_pic,
              };

              this.shareservice.updatechannelCredentials(payload).subscribe({
                next: (response) => {
                  if (response.status_code === 200) {
                    this.alertMsg.alertSuccess(
                      'Success',
                      response.data.message
                    );
                    this.getBusinessAccounts();
                    // this.store.dispatch(
                    //   dashboardactions.getChannelCredentials({
                    //     payload: this.account_dtls,
                    //   })
                    // );
                    this.businessprofile_pic = false;
                  }
                },
                error: (error) => {
                  if (error.message) {
                    const dispErr = error.message;
                    this.alertMsg.alertDanger('Error', dispErr);
                  }
                },
              });
            }
          }
        },
        error: (error) => {
          if (error.message) {
            const dispErr = error.message;
            this.alertMsg.alertDanger('Error', dispErr);
          }
        },
      });
  }

  AuthDetails(data: any, action: string) {
    let credentialmeta = JSON.parse(data);
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (typeof credentialmeta === 'string') {
      credentialmeta = JSON.parse(credentialmeta);
    }
    if (action === 'username') {
      return credentialmeta.metaData.username;
    } else if (action === 'password') {
      return credentialmeta.metaData.password;
    }
  }

  copyCode() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) =>
        resolve(e.target?.result);
      reader.onerror = () => reject(null);
      file ? reader.readAsDataURL(file) : reject(null);
    });
  }

  onNavChange(event: any) {
    return;
  }

  onPageEvent(event: any) {
    this.ssoParams = JSON.parse(JSON.stringify(this.ssoParams));
    this.ssoParams.page = event;
    this.store.dispatch(
      dashboardactions.getServiceAccountsWithParams({
        SA_Params: this.ssoParams,
      })
    );
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  getFilterData(event: boolean) {
    if (event) {
      this.onDropDownStatus('Customer');
      this.store.dispatch(
        getFiletrsData({
          customerParams: this.ssoCustomerAndWalletparams,
          walletParams: this.ssoCustomerAndWalletparams,
        })
      );
    }
  }

  getAccountName() {
    if (environment.sso) {
      const accountType =
        this.loggedInUserDetails.account.account_type.toUpperCase();
      if (
        this.shareservice.getCustomerTypes().has(accountType) ||
        this.shareservice.getPartnerTypes().has(accountType)
      ) {
        return 'Business';
      } else if (accountType === 'ROOT') {
        return 'Service';
      } else {
        return undefined;
      }
    } else {
      return 'Business';
    }
  }

  onScrollToEnd(type: string) {
    this.ssoCustomerAndWalletparams = JSON.parse(
      JSON.stringify(this.ssoCustomerAndWalletparams)
    );
    console.log(type);

    if (type === 'Customer') {
      this.ssoCustomerAndWalletparams.page += 1;
      if (
        this.shareservice.checkPageExists(
          this.Svcustomerdata.count,
          this.ssoCustomerAndWalletparams.size,
          this.ssoCustomerAndWalletparams.page
        )
      ) {
        this.getSVCustomers();
      }
    } else if (type === 'Wallets') {
      this.ssoCustomerAndWalletparams.page += 1;
      if (
        this.shareservice.checkPageExists(
          this.Svwalletsdata.count,
          this.ssoCustomerAndWalletparams.size,
          this.ssoCustomerAndWalletparams.page
        )
      ) {
        this.getSVWallets();
      }
    }
  }
  getSVCustomers() {
    this.store.dispatch(
      GetSvCustomers.getSVCustomers({
        params: this.ssoCustomerAndWalletparams,
        filter: true,
      })
    );
  }
  getSVWallets() {
    this.store.dispatch(
      GetSvWallets.getSVWallets({
        params: this.ssoCustomerAndWalletparams,
        filter: true,
      })
    );
  }

  onDropDownStatus(type: string) {
    this.ssoCustomerAndWalletparams = JSON.parse(
      JSON.stringify(this.ssoCustomerAndWalletparams)
    );
    this.ssoCustomerAndWalletparams.page = 1;
    this.ssoCustomerAndWalletparams.sv_customer_id = null;
    this.ssoCustomerAndWalletparams.wallet_name = null;
  }

  onSearch(
    event: {
      term: string;
      items: any[];
    },
    type: string
  ) {
    this.ssoCustomerAndWalletparams = JSON.parse(
      JSON.stringify(this.ssoCustomerAndWalletparams)
    );
    console.log(event);
    if (type === 'Customer') {
      console.log(event.term);
      this.ssoCustomerAndWalletparams.page = 1;
      this.ssoCustomerAndWalletparams.sv_customer_id = event?.term;
      this.getSVCustomers();
    } else if (type === 'Wallets') {
      console.log(event.term);
      this.ssoCustomerAndWalletparams.page = 1;
      this.ssoCustomerAndWalletparams.wallet_name = event?.term;
      this.getSVWallets();
    }
  }

  onChooseFilter(event: any, type: string) {
    if (event) {
      this.ssoParams = JSON.parse(JSON.stringify(this.ssoParams));
      this.ssoParams.page = 1;
      switch (type) {
        case 'Wallet_Mode':
          this.ssoParams.wallet_mode = event.id;
          this.applyFilter();
          break;
        case 'BSP':
          this.ssoParams.bsp = event.name;
          this.applyFilter();
          break;
        case 'Customer':
          this.ssoParams.sv_customer_id = event.sv_customer_id;
          this.applyFilter();
          break;
        case 'Wallets':
          this.ssoParams.wallet_name = event.wallet_name;
          this.applyFilter();
          break;
        default:
          break;
      }
    }
  }

  clearFilter(type: string) {
    this.ssoParams = JSON.parse(JSON.stringify(this.ssoParams));
    if (type === 'clearfilter') {
      this.ssoParams.bsp = null;
      this.ssoParams.wallet_mode = null;
      this.ssoParams.sv_customer_id = null;
      this.ssoParams.wallet_name = null;
      this.ssoSuperAdminFilters?.reset();
    } else if (type === 'sv_customer_id') {
      this.ssoSuperAdminFilters.controls[type]?.reset();
      this.ssoParams[type] = null;
    } else if (type === 'wallet_mode') {
      this.ssoSuperAdminFilters.controls[type]?.reset();
      this.ssoParams[type] = null;
    } else if (type === 'bsp') {
      this.ssoSuperAdminFilters.controls[type]?.reset();
      this.ssoParams[type] = null;
    } else if (type === 'wallet_name') {
      this.ssoSuperAdminFilters.controls[type]?.reset();
      this.ssoParams[type] = null;
    }
    this.applyFilter();
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
