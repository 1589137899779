import { createFeature, createReducer, on } from '@ngrx/store';
import {
  IAppState,
  SvcustomersResponse,
  SvwalletsResponse,
} from 'src/app/appdata/shared.data';
import { GetSvCustomers, GetSvWallets } from './serviceaccount.actions';

export interface SvcustomersState
  extends IAppState<
    'Svcustomer',
    'data' | 'isLoading' | 'currentPage' | 'pageSize' | 'error',
    SvcustomersResponse['data'] | null
  > {}

export const initialSvcustomersState: SvcustomersState = {
  Svcustomerdata: null,
  SvcustomerisLoading: false,
  SvcustomercurrentPage: 1,
  SvcustomerpageSize: 10,
  Svcustomererror: null,
};

export const SvcustomersFeature = createFeature({
  name: 'Svcustomers',
  reducer: createReducer(
    initialSvcustomersState,
    on(
      GetSvCustomers.getSVCustomers,
      (state): SvcustomersState => ({
        ...state,
        SvcustomerisLoading: true,
        Svcustomererror: null,
      })
    ),
    on(
      GetSvCustomers.getSVCustomersSuccess,
      (state, { data }): SvcustomersState => ({
        ...state,
        Svcustomerdata: data,
        SvcustomerisLoading: false,
        Svcustomererror: null,
      })
    ),
    on(
      GetSvCustomers.getSVCustomersFailure,
      (state, { error }): SvcustomersState => ({
        ...state,
        Svcustomerdata: null,
        SvcustomerisLoading: false,
        Svcustomererror: error,
      })
    )
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectSvcustomersState, // feature selector
  selectSvcustomerdata, // selector for `books` property
  selectSvcustomerisLoading, // selector for `loading` property
  selectSvcustomererror, //selector for `error` property
} = SvcustomersFeature;
